
import { ActionIcon, Avatar, Box, Button, Group, LoadingOverlay, Menu, Text, UnstyledButton } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import React from 'react';
import InputField, { InputFieldTypes } from '../components/input_field';
import { useAuth } from '../contexts/auth.context';
import { UPDATE_SELF_COMPANY } from '../services/companies';
import { GET_USER_DATA, UPDATE_USER } from '../services/users';
import { FaImage, FaPlus, FaSave } from 'react-icons/fa';
import { phoneCountryCodes } from '../utility/util';
import { useApp } from '../contexts/app.context';

export default function ProfilePage({ company: companyProfile = false }) {
    const [user, setUser] = React.useState<any>(null);
    const [company, setCompany] = React.useState(null);
    const [loadingSave, setLoadingSave] = React.useState(false);
    const [loadingData, setLoadingData] = React.useState(false);
    const [openImage, setOpenImage] = React.useState(false);
    const [openIcon, setOpenIcon] = React.useState(false);

    const { role } = useAuth();
    const { setConfigs } = useApp();

    let params = {
        image: user?.image,
        name: user?.name,
        phone_code: user?.phone_code,
        phone: user?.phone,
        company_name: user?.company_name,
        website: user?.website,
        custom: user?.custom,
    }

    let companyParams = {
        image: company?.image,
        icon: company?.icon,
        name: company?.name,
        tagline: company?.tagline,
        website: company?.website,
        social: company?.social,
        phone_code: company?.phone_code,
        phone: company?.phone,
        email: company?.email,
    }

    const handleChange = dt => setUser(u => ({ ...u, ...dt }))
    const handleChangeCompany = dt => setCompany(u => ({ ...u, ...dt }))
    const handleChangeCustom = dt => setUser(u => ({ ...u, custom: { ...u.custom, ...dt } }))

    const handleSave = () => {
        UPDATE_USER(user?._id, params)
            .then(() => {
                notifications.show({ message: "Profile updated", color: "green" })
            })
            .catch(err => {
                notifications.show({ title: "Ouch.", message: err.message, color: 'red' })
            })
    }

    const handleSaveCompany = () => {
        UPDATE_SELF_COMPANY(companyParams)
            .then(() => {
                notifications.show({ message: "Company updated", color: "green" })
            })
            .catch(err => {
                notifications.show({ title: "Ouch.", message: err.message, color: 'red' })
            })
    }

    const loadData = () => {
        setLoadingData(true)
        GET_USER_DATA()
            .then(({ user, company }) => {
                setLoadingData(false)
                setUser(user)
                setCompany(company)
            })
            .catch(err => {
                setLoadingData(false)
                notifications.show({ title: "Ouch.", message: err.message, color: 'red' })
            })
    }

    React.useEffect(() => { loadData() }, [])

    return <>
        <LoadingOverlay visible={loadingData} />
        {companyProfile
            ? <>
                <Group>
                    <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Box>
                            {
                                company?.image
                                    ? <img src={company?.image} height={80} width={200} color="gray" style={{ objectFit: 'contain' }} />
                                    : <FaImage size={60} color="#7a7a7a" />
                            }
                        </Box>
                        <Menu opened={openImage} onOpen={() => setOpenImage(true)} onClose={() => setOpenImage(false)}>
                            <Menu.Target>
                                <UnstyledButton>
                                    <Button>Upload Logo</Button>
                                </UnstyledButton>
                            </Menu.Target>
                            <Menu.Dropdown>
                                <InputField
                                    name="image"
                                    title='Brand Logo'
                                    value={company?.image}
                                    onChange={dt => {
                                        handleChangeCompany(dt);
                                        setOpenImage(false);
                                        if (dt.image) setConfigs({ image: dt.image })
                                    }}
                                    fieldType={InputFieldTypes.IMAGE}
                                />
                            </Menu.Dropdown>
                        </Menu>
                    </div>
                    <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Box>
                            {
                                company?.icon
                                    ? <img src={company?.icon} height={80} width={200} color="gray" style={{ objectFit: 'contain' }} />
                                    : <FaImage size={60} color="#7a7a7a" />
                            }
                        </Box>
                        <Menu opened={openIcon} onOpen={() => setOpenIcon(true)} onClose={() => setOpenIcon(false)}>
                            <Menu.Target>
                                <UnstyledButton>
                                    <Button>Upload Icon</Button>
                                </UnstyledButton>
                            </Menu.Target>
                            <Menu.Dropdown>
                                <InputField
                                    name="icon"
                                    title='Brand Logo'
                                    value={company?.icon}
                                    onChange={dt => {
                                        handleChangeCompany(dt);
                                        setOpenIcon(false);
                                        if (dt.icon) setConfigs({ icon: dt.icon })
                                    }}
                                    fieldType={InputFieldTypes.IMAGE}
                                />
                            </Menu.Dropdown>
                        </Menu>
                    </div>
                </Group>


                <InputField mt="md" name="email" value={company?.email} onChange={handleChangeCompany} title="Email" />
                <InputField mt="md" name="name" value={company?.name} onChange={(dt) => {
                    handleChangeCompany(dt);
                    setConfigs({ name: dt.name })
                }} title="Name" />
                <InputField mt="md" name="tagline" value={company?.tagline} onChange={handleChangeCompany} title="Tag Line" />
                <InputField mt="md" name="website" value={company?.website} onChange={handleChangeCompany} title="Website" />
                <Group mt="md">
                    <InputField
                        style={{ flex: 0.3 }}
                        title="Country Code"
                        name="phone_code"
                        autoComplete='off'
                        fieldType={InputFieldTypes.SELECT}
                        value={company?.phone_code}
                        onChange={handleChangeCompany}
                        nothingFound="No country code found"
                        options={phoneCountryCodes}
                    />
                    <InputField style={{ flex: 0.7 }} name="phone" value={company?.phone} onChange={handleChangeCompany} title="Phone" />
                </Group>
                <Group mt='md'>
                    <Text style={{ flex: 1 }}>Social Links</Text>
                    <ActionIcon variant='outline' onClick={() => setCompany(c => ({ ...c, social: [...(c?.social ?? []), {}] }))}>
                        <FaPlus />
                    </ActionIcon>
                </Group>
                {(company?.social ?? []).map((s, i) => <Group grow mt="xs">
                    <InputField placeholder='Type' name="type" value={s.type}
                        fieldType={InputFieldTypes.SELECT}
                        options={[
                            // { value: "phone", label: "Phone" },
                            // { value: "e-mail", label: "E-mail" },
                            { value: "whatsapp", label: "Whatsapp" },
                            { value: "facebook", label: "Facebook" },
                            { value: "instagram", label: "Instagram" },
                            { value: "twitter", label: "Twitter" },
                            { value: "snapchat", label: "Snapchat" },
                            { value: "pinterest", label: "Pinterest" },
                            { value: "linkedin", label: "Linkedin" },
                        ]}
                        onChange={({ type }) => {
                            setCompany(c => ({ ...c, social: (c?.social ?? []).map((it, j) => i === j ? { ...it, type } : it) }))
                        }} />
                    <InputField placeholder='Url' name="url" value={s.url} onChange={({ url }) => {
                        setCompany(c => ({ ...c, social: (c?.social ?? []).map((it, j) => i === j ? { ...it, url } : it) }))
                    }} />
                </Group>)}

                <Group justify="flex-end" mt="md">
                    <Button
                        size="md"
                        onClick={handleSaveCompany}
                        loading={loadingSave}
                        style={{ background: 'black' }}
                        rightSection={<FaSave />}
                    >
                        Save Company Details
                    </Button>
                </Group>
            </>
            : <>
                <Menu opened={openImage} onOpen={() => setOpenImage(true)} onClose={() => setOpenImage(false)}>
                    <Menu.Target>
                        <UnstyledButton>
                            <Avatar src={user?.image} size={80} c="gray" style={{ border: '1px solid #EFEFEF' }} />
                        </UnstyledButton>
                    </Menu.Target>
                    <Menu.Dropdown>
                        <InputField
                            name="image"
                            title='Profile Avatar'
                            value={user?.image}
                            onChange={dt => {
                                handleChange(dt);
                                setOpenImage(false);
                            }}
                            fieldType={InputFieldTypes.IMAGE}
                        />
                    </Menu.Dropdown>
                </Menu>

                <InputField mt="md" name="email" value={user?.email} onChange={handleChange} title="Email" disabled />
                <InputField mt="md" name="name" value={user?.name} onChange={handleChange} title="Name" />
                <Group mt="md" >
                    <InputField
                        style={{ flex: 0.3 }}
                        title="Country Code"
                        name="phone_code"
                        autoComplete='off'
                        fieldType={InputFieldTypes.SELECT}
                        value={user?.phone_code}
                        onChange={handleChange}
                        nothingFound="No country code found"
                        options={phoneCountryCodes}
                    />
                    <InputField name="phone" value={user?.phone} onChange={handleChange} title="Phone" />
                </Group>
                <InputField mt="md" name="company_name" value={user?.company_name} onChange={handleChange} title="Company / Brand" />
                <InputField mt="md" name="website" value={user?.website} onChange={handleChange} title="Website" />
                <InputField mt="md" name="address" value={user?.address} onChange={handleChange} title="Address" />
                {role?.profile?.title === "Client" && (company?.customFields || [])
                    .filter(field => field.title)
                    .map(field => (
                        <InputField mt="md" name={field._id} value={{ ...user?.custom }[field._id]} onChange={handleChangeCustom} title={field.title} />
                    ))}

                <Group justify="flex-end" mt="md">
                    <Button
                        size="md"
                        onClick={handleSave}
                        loading={loadingSave}
                        style={{ background: 'black' }}
                        rightSection={<FaSave />}
                    >
                        Save
                    </Button>

                </Group>

            </>}
    </>
}
