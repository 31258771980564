import React, { useEffect } from 'react';
import {
    AppShell,
    Box,
    Button,
    Group,
    Text
} from '@mantine/core';
import { useNavigate } from "react-router-dom";
import { FaRocket } from "react-icons/fa";

import { useAuth } from '../contexts/auth.context';

export const NotFoundCompanyPage = () => {
    const { companyHost } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        // Set 404 page title
        document.title = '404 - Page Not Found';
    }, []);

    return <AppShell>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100vw', height: '100vh' }}>
            <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} p="md" mb="xl">
                <Group justify='center'>
                    <img alt="retainr" src={companyHost?.image ? companyHost.image : `/logo.png`} style={{ maxHeight: 80, maxWidth: '70%' }} />
                </Group>
                <Text mt="xl" size="xl" ta="center">This page isn't available yet!</Text>
                <Group mt="xl" justify='center'>
                    <Button
                        className='draw-button'
                        size="md"
                        onClick={() => navigate('/signup')}
                        rightSection={<FaRocket />}
                    >
                        Start Creating Your Sales Page Today
                    </Button>
                </Group>
            </Box>
        </div>
    </AppShell>
};
