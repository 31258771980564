import React, { useEffect } from 'react';

interface RedirectProps {
    url: string;
    loadingMessage?: string;
}

export const Redirect: React.FC<RedirectProps> = ({
    url,
    loadingMessage = 'Redirecting...'
}) => {
    useEffect(() => {
        window.location.href = url;
    }, [url]);

    return (
        <div className="flex items-center justify-center p-8">
            <p>{loadingMessage}</p>
        </div>
    );
};
