import React, { useEffect, useState } from 'react';
import SimpleHeader from '../components/simple_header';
import { ActionIcon, Avatar, Box, Button, Grid, Group, Image, Modal, Paper, Text, Title, UnstyledButton } from '@mantine/core';
import InputField, { InputFieldTypes } from '../components/input_field';
import { useApp } from '../contexts/app.context';
import { useAuth } from '../contexts/auth.context';
import { DELETE_INVITE, GET_ALL_INVITES, SAVE_SEAT_PLAN, SEND_INVITE, UPGRADE_SEATS_QUANTITY } from '../services/companies';
import { notifications } from '@mantine/notifications';
import { GET_ALL_PLANS } from '../services/plans';
import { extenseRecurrenceType, getExtenseDatetime } from '../utility/util';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { DELETE_USER } from '../services/users';
import { useLanguage } from '../contexts/language.context';
import { GET_ALL_PROFILES } from '../services/profiles';
import { useMediaQuery } from '@mantine/hooks';

export default function UsersPage({ }) {
    const [plans, setPlans] = useState([]);
    const [seats, setSeats] = useState(0);
    const [invites, setInvites] = useState([]);
    const [addingSeat, setAddingSeat] = useState(false);
    const [changingPlan, setChangingPlan] = useState(null);

    const { agents, loadAgents, confirmDialog } = useApp();
    const { str } = useLanguage();
    const { planData, userData, loadUserData, role } = useAuth();

    const isMobile = useMediaQuery('(max-width: 900px)');

    const [inviteMails, setInviteMails] = useState({ email: "", profile: null });
    const [profiles, setProfiles] = React.useState<any[]>([]);

    const loadProfiles = () => {
        GET_ALL_PROFILES()
            .then(res => {
                setProfiles(res);
                setInviteMails(im => ({ ...im, profile: res.find(r => r.title === "Project Manager")?._id }))
            })
            .catch(err => { })
    }

    const handleInvite = () => {
        let previous = inviteMails;
        setInviteMails(im => ({ ...im, email: "" }))
        SEND_INVITE({ email: previous.email, profile: previous.profile })
            .then(res => {
                loadInvites();
                notifications.show({ message: str(`INVITE_SENT`), color: "green" })
            })
            .catch(err => {
                setInviteMails(previous);
                const errorMessage = err.response?.data?.message || err.message || "An error occurred while sending the invitation";
                notifications.show({
                    title: "Invitation Error",
                    message: errorMessage,
                    color: "red"
                });
            })
    }

    const loadInvites = () => {
        GET_ALL_INVITES()
            .then(res => {
                setInvites(res.filter(i => i?.profile?.title !== "Client" && i.status === 0));
            })
            .catch(err => { })
    }

    const loadPlans = () => {
        GET_ALL_PLANS()
            .then(res => {
                setPlans(res);
            })
            .catch(err => {
                notifications.show({ message: err.message, color: "red" })
            })
    }

    const handleSave = (planId = null) => {
        UPGRADE_SEATS_QUANTITY(seats, planId)
            .then((res) => {
                if (res.payed) {
                    loadUserData();
                } else {
                    if (res.return_url) window.open(res.return_url, "_blank");
                    else notifications.show({ message: "You need to complete the payment process at your bank. Check your notifications and approve the transaction to proceed", color: "yellow" })
                }
            })
            .catch(err => {
                notifications.show({ message: err.message, color: "red" })
            })
    }

    const handleChangePlan = (seatId, planId) => {
        SAVE_SEAT_PLAN(seatId, planId)
            .then(() => {
                loadUserData();
            })
            .catch(err => {
                notifications.show({ message: err.message, color: "red" })
            })
    }

    useEffect(() => {
        loadPlans();
        loadProfiles();
        loadInvites();
    }, []);

    useEffect(() => {
        setSeats((planData?.seats || []).length <= 0 ? 1 : (planData?.seats || []).length)
    }, [planData]);

    return <>
        <SimpleHeader title="Manage Team" />

        {/* <Paper p="md" style={{background: '#DFDFDF'}}>
            <InputField
                title="Team Members Quantity"
                name="seats"
                fieldType={InputFieldTypes.QUANTITY}
                value={seats}
                onChange={({seats}) => {
                    let minimun = (planData?.seats || []).filter(s => s.plan?.service?.plan_type === "one-time").length;
                    minimun = minimun > 0 ? minimun : 1;
                    setSeats(parseInt(seats) >= minimun ? seats : minimun);
                }}
            />
            {seats !== (planData?.seats || []).length && <Group justify="flex-end" mt="md">
                <Button onClick={() => {
                    if(seats > (planData?.seats || []).length) setAddingSeat(true);
                    else handleSave();
                }} style={{backgroundColor: 'black'}}>{ seats > (planData?.seats || []).length ? 'Upgrade' : 'Downgrade'}</Button>
            </Group>}
        </Paper> */}
        <Paper p="md" style={{ background: '#FAFAFA' }}>
            <Group style={{ flexDirection: isMobile ? "column" : "row" }}>
                <InputField
                    style={{ flex: 1 }}
                    name="email"
                    value={inviteMails.email}
                    onChange={({ email }) => setInviteMails(im => ({ ...im, email }))}
                    variant="filled"
                    placeholder={str("HEADER.TYPE_EMAIL_TO_INVITE")}
                />
                <InputField
                    name="profile"
                    onChange={({ profile }) => setInviteMails(im => ({ ...im, profile }))}
                    value={inviteMails.profile}
                    searchable={false}
                    clearable={false}
                    style={{ width: 220 }}
                    fieldType={InputFieldTypes.SELECT}
                    options={profiles
                        .filter(p =>
                            (p.title !== "Owner" || ["Owner"].includes(role?.profile?.title))
                            && p.title !== "Client")
                        .map(p => ({ label: p.title, value: p._id }))}
                />
                <Button
                    size="md"
                    disabled={
                        !inviteMails.email ||
                        !inviteMails.profile ||
                        ["Support", "Project Manager"].includes(role?.profile?.title)
                    }
                    variant="filled" color="orange" onClick={handleInvite}>{str("INVITE")}</Button>
            </Group>
            <Text mt="xs" c="gray" size="xs">{{
                "Support": "ⓘ Access to support portal for handling client requests.",
                "Project Manager": "ⓘ Navigates service orders and delegates tasks to team members.",
                "Admin": "ⓘ Total access to all roles and portals.",
                "Owner": "ⓘ Total access to all roles and portals.",
            }[profiles.find(p => p._id === inviteMails.profile)?.title]}</Text>
        </Paper>

        {
            (Array.from({
                length: (agents.length + invites.length) > (planData?.seats || []).length
                    ? (agents.length + invites.length)
                    : (planData?.seats || []).length
            })).map((s, i) => (
                <Paper p="md" mt="md">
                    <Group>
                        <Title order={4}>#{i + 1}</Title>
                        {agents[i] && <Avatar size="lg" src={agents[i]?.image} />}
                        <Group style={{ flex: 1, flexDirection: isMobile ? "column" : "row" }}>
                            {agents[i]
                                ? <Box>
                                    <Title order={4}>{agents[i].name}</Title>
                                    <Text size="sm" c="gray">{agents[i].profile?.title}</Text>
                                </Box>
                                : invites[i - agents.length]
                                    ? <Box>
                                        <Title order={4}>Invite sent. Please ask them to check their email as well as junk folder</Title>
                                        <Text size="sm" c="gray">{invites[i - agents.length].email}</Text>
                                    </Box>
                                    : invites.length >= i - agents.length && <>
                                        <InputField
                                            style={{ flex: 1 }}
                                            name="email"
                                            value={inviteMails.email}
                                            onChange={({ email }) => setInviteMails(im => ({ ...im, email }))}
                                            variant="filled"
                                            placeholder={str("HEADER.TYPE_EMAIL_TO_INVITE")}
                                        />
                                        <InputField
                                            name="profile"
                                            onChange={({ profile }) => setInviteMails(im => ({ ...im, profile }))}
                                            value={inviteMails.profile}
                                            searchable={false}
                                            clearable={false}
                                            style={isMobile ? {} : { maxWidth: 120 }}
                                            fieldType={InputFieldTypes.SELECT}
                                            options={profiles
                                                .filter(p =>
                                                    (p.title !== "Owner" || ["Owner"].includes(role?.profile?.title))
                                                    && p.title !== "Client")
                                                .map(p => ({ label: p.title, value: p._id }))}
                                        />
                                        <Button
                                            disabled={["Support", "Project Manager"].includes(role?.profile?.title)}
                                            variant="filled" color="orange" onClick={handleInvite}>{str("INVITE")}</Button>
                                    </>}
                        </Group>
                        {
                            agents[i]
                                ? <ActionIcon
                                    disabled={
                                        userData?.user?._id === agents[i]._id ||
                                        (agents[i].profile.title === "Owner" && (role?.profile?.title !== "Owner")) ||
                                        ["Support", "Project Manager"].includes(role?.profile?.title)
                                    }
                                    onClick={() => {
                                        confirmDialog({ text: str("CONFIRMATION.DELETE_USER") }, ({ confirmed }) => {
                                            if (confirmed) DELETE_USER(agents[i]._id)
                                                .then(() => {
                                                    loadAgents();
                                                })
                                                .catch(err => {
                                                    notifications.show({ message: err.message, color: "red" })
                                                })
                                        })
                                    }}><FaTrash /></ActionIcon>
                                : invites[i - agents.length] && <ActionIcon onClick={() => {
                                    confirmDialog({ text: str("CONFIRMATION.DELETE_INVITE") }, ({ confirmed }) => {
                                        if (confirmed) DELETE_INVITE(invites[i - agents.length]._id)
                                            .then(() => {
                                                loadInvites();
                                            })
                                            .catch(err => {
                                                notifications.show({ message: err.message, color: "red" })
                                            })
                                    })
                                }}><FaTrash /></ActionIcon>
                        }
                        {/* {(planData?.seats || [])[i] && <Button 
                            onClick={() => setChangingPlan({
                                seatId: (planData?.seats || [])[i]._id,
                                planId: (planData?.seats || [])[i].plan?._id,
                            })}
                            variant='outline'
                            leftSection={<FaEdit />}>
                            <Text size="sm" fw="bold">{(planData?.seats || [])[i].plan?.service?.plan_name}</Text>
                        </Button>} */}
                    </Group>
                    {/* {
                        (planData?.seats || [])[i]?.plan?.service?.plan_type === "one-time"
                        ? <Text c="gray" size="xs" mt="xs" ta="right">Lifetime Plan</Text>
                        : <Text c="gray" size="xs" mt="xs" ta="right">Active until: {(planData?.seats || [])[i] && getExtenseDatetime((planData?.seats || [])[i].active_until, false)}</Text>
                    } */}
                </Paper>
            ))
        }

        <Modal
            opened={addingSeat || changingPlan}
            onClose={() => { setAddingSeat(false); setChangingPlan(null) }}
            title={<Title order={5}>Choose a plan to upgrade seats</Title>}
            size="calc(75vw)"
        >
            <Grid mt="md" mb="md">
                {plans.map(plan => (
                    <Grid.Col span={{ base: 12, md: 6 }}>
                        <UnstyledButton style={{ width: '100%' }} onClick={() => {
                            if (changingPlan) {
                                if (changingPlan?.planId === plan._id) setChangingPlan(null);
                                else handleChangePlan(changingPlan.seatId, plan._id);
                            } else handleSave(plan._id);
                        }}>
                            <Paper shadow='xs' p="md" style={{ border: changingPlan?.planId === plan._id ? '3px solid #FF4D18' : '1px solid black' }}>
                                <Text c="gray" size="sm">Starting from {plan.service.currency} {parseFloat(plan.service.unity_price ?? 0).toFixed(2)}</Text>
                                <Image src={plan.service.cover_image} width="100%" />
                                <Text c="gray" size="xs">{plan.service.plan_type === "subscription" ? extenseRecurrenceType(plan.service.recurrence_type) : "One-time"}</Text>
                                <Title order={3} mt="sm">{plan.service.plan_name}</Title>
                            </Paper>
                        </UnstyledButton>
                    </Grid.Col>
                ))}
            </Grid>
        </Modal>
    </>
}
