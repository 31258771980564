import {
    AppShell,
    LoadingOverlay,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { default as React, useEffect, useState } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useNavigate, useParams } from 'react-router-dom';

import { useApp } from '../contexts/app.context';
import { useAuth } from '../contexts/auth.context';
import { GET_PUBLIC_JOB, GET_PUBLIC_LINK_DATA } from '../services/auth';
import Signup from './signup';
import { formatLabel } from '../utility/util';

export default function PublicPage() {
    const { key } = useParams();
    const navigate = useNavigate();
    const app = useApp();
    const { userData, companyHost } = useAuth();
    const isMobile = useMediaQuery('(max-width: 1200px)');

    const [data, setData] = useState<any>(null);
    const [order, setOrder] = useState<any>(null);
    const [selectedService, setSelectedService] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        if (!companyHost?.services || !key) return;

        const some = (companyHost.services).find(s =>
            formatLabel(s.label ?? s.plan_name) === formatLabel(key)
        );

        if (some) {
            if (userData) {
                navigate(`/?service=${some._id}`);
            } else {
                setSelectedService(some);
            }
        } else {
            setSelectedService(null);
        }
    }, [userData, companyHost, key, navigate]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (!key) {
                    navigate('/unpublished-company');
                    return;
                }

                setLoading(true);
                if (key.startsWith("o-")) {
                    if (companyHost) {
                        const res = await GET_PUBLIC_JOB(key.replace("o-", ""));
                        setOrder(res);
                    }
                } else {
                    const res = await GET_PUBLIC_LINK_DATA(key);
                    if (!res?.type) {
                        navigate('/unpublished-company');
                        return;
                    }
                    setData(res);
                }
            } catch (error) {
                navigate('/unpublished-company');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [key, companyHost, navigate]);

    if (selectedService || order) {
        return <Signup
            customer
            order={order}
            service={selectedService ?? order?.service}
        />;
    }

    if (data?.type === "affiliate") {
        return <Signup shareLinkId={data?._id} />;
    }

    return (
        <AppShell padding={0}>
            <LoadingOverlay visible={loading} />
        </AppShell>
    );
}
