import {
  Box,
  Button,
  Grid,
  Group,
  Paper, Text, Title
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import React, { useEffect, useState } from "react";
import InputField from "../components/input_field";
import { PaymentForm } from "../components/payment_form";
import Table from "../components/table";
import { useApp } from "../contexts/app.context";
import { useAuth } from "../contexts/auth.context";
import { APPLY_COUPON, SAVE_COMPANY_PAYMENT_METHOD, UNSUBSCRIBE_COMPANY } from "../services/companies";
import { GET_PLAN_PAYMENTS, GET_STRIPE_CONFIGS, SAVE_PAYMENT_METHOD } from "../services/payments";
import { extenseRecurrenceType, getExtenseDatetime } from "../utility/util";
import moment from "moment";

export default function Billing() {
  const [paymentMethod, setPaymentMethod] = useState<any>(null);
  const [coupon, setCoupon] = useState<any>(null);
  const [payments, setPayments] = useState<any>([]);
  const [loadingUpdatePlan, setLoadingUpdatePlan] = useState<boolean>(false);
  const [loadingUpdateCoupon, setLoadingUpdateCoupon] = useState<boolean>(false);

  const { userData, loadUserData, ip } = useAuth();
  const { confirmDialog, startPlan } = useApp();

  const loadPayments = () => {
    GET_PLAN_PAYMENTS()
      .then(({ payments: ps = [] }) => {
        setPayments(ps);
      })
      .catch(() => { })
  }

  const handleUpdateMethod = (methodId, others = {}) => {
    setLoadingUpdatePlan(true)
    SAVE_COMPANY_PAYMENT_METHOD({ payment: { method: methodId, ...others } })
      .then(res => {
        loadUserData();
        setLoadingUpdatePlan(false);
      })
      .catch(err => {
        setLoadingUpdatePlan(false)
        notifications.show({ message: err.message, color: "red" })
      })
  }

  const handleChangePaymentMethod = async () => {
    setLoadingUpdatePlan(true);
    if (paymentMethod.method === "new-card") {
      try {
        const { retainr } = await GET_STRIPE_CONFIGS("retainr")
        const stripe = require('stripe')(retainr);
        const pm = await stripe.paymentMethods.create({
          type: 'card',
          card: paymentMethod?.card,
        })
        const method = await SAVE_PAYMENT_METHOD("retainr", pm, { email: userData.company.email, name: userData.company.name })
        handleUpdateMethod(method._id, { push: true });
      } catch (err) {
        setLoadingUpdatePlan(false)
        notifications.show({ message: err?.message ?? "Stripe not configured" });
      }
    } else {
      handleUpdateMethod(paymentMethod.method);
    }
  }

  const handleUpdateCoupon = () => {
    setLoadingUpdateCoupon(true)
    APPLY_COUPON(coupon)
      .then(res => {
        loadUserData();
        setLoadingUpdateCoupon(false);
        notifications.show({ message: "Discount applied", color: "green" })
      })
      .catch(err => {
        setLoadingUpdateCoupon(false)
        notifications.show({ message: err.message, color: "red" })
      })
  }

  useEffect(() => {
    loadPayments()
  }, [])

  useEffect(() => {
    setPaymentMethod({ method: userData?.activePaymentMethod?._id });
    setCoupon(userData?.plan?.coupon?.title);
  }, [userData])

  return <>
    <Grid mb="md">
      <Grid.Col span={{ base: 12, md: 6 }}>
        <Paper p="md" style={{ minHeight: 280, display: 'flex', flexDirection: 'column' }}>
          <Title order={4}>Your Plan</Title>
          <Title order={5} c="gray" mb="md">Pricing that suits all.</Title>

          <Box style={{ flex: 1 }} mb="md">
            <Paper shadow='xs' p="md" style={{ border: '1px solid #DFDFDF', cursor: 'pointer' }} onClick={() => startPlan()}>
              <Text c="gray" size="sm">{(userData?.plan?.seats ?? [])[0]?.plan?.service?.currency} {parseFloat((userData?.plan?.seats ?? [])[0]?.plan?.service?.unity_price).toFixed(2)}</Text>
              <Text c="gray" size="xs">{(userData?.plan?.seats ?? [])[0]?.plan?.service?.plan_type === "subscription" ? extenseRecurrenceType((userData?.plan?.seats ?? [])[0]?.plan?.service?.recurrence_type) : "One-time"}</Text>
              <Text c="gray" size="sm" mt="md">{(userData?.plan?.seats ?? [])[0]?.plan?.service?.description}</Text>
              <Title order={4} mt="sm">{(userData?.plan?.seats ?? [])[0]?.plan?.service?.plan_name}</Title>
            </Paper>
          </Box>

          <Group justify="flex-end">
            {userData?.activePaymentMethod && <Button color="gray" variant="transparent" onClick={() => {
              confirmDialog({ text: "You'll loose all your data and access to the platform, do you wish to proceed to canceling your subscription?" }, ({ confirmed }) => {
                if (confirmed) UNSUBSCRIBE_COMPANY()
                  .then(() => {
                    notifications.show({ message: "Your company was unsubscribed, if you want, just insert another credit card to return to your plan.", color: "yellow" });
                    loadUserData();
                  })
                  .catch((err) => {
                    notifications.show({ message: err.message, color: "red" })
                  })
              })
            }}>Unsubscribe</Button>}
            <Button className="draw-button" onClick={() => startPlan()}>Upgrade</Button>
          </Group>
        </Paper>
      </Grid.Col>
      <Grid.Col span={{ base: 12, md: 6 }}>
        <Paper p="md" style={{ minHeight: 280, display: 'flex', flexDirection: 'column' }}>
          <Title order={4}>Payment Method</Title>
          <Title order={5} c="gray" mb="md">Choose your principal payment method</Title>

          <Box style={{ flex: 1 }}>
            <PaymentForm
              data={paymentMethod}
              methods={userData?.paymentMethods}
              onChange={setPaymentMethod}
            />
            {
              userData?.activePaymentMethod &&
              (userData?.plan?.seats ?? [])[0]?.plan?.service?.plan_type === "subscription" &&
              <Text size="xs" ta="right">Next payment: {moment((userData?.plan?.seats ?? [])[0].active_until).add(1, "days").format("MMM DD, YYYY")}</Text>
            }
          </Box>
          <Group justify="flex-end">
            <Button className="draw-button" loading={loadingUpdatePlan} onClick={handleChangePaymentMethod}>Update</Button>
          </Group>
        </Paper>
      </Grid.Col>
    </Grid>

    <Paper p="md" mb="md">
      <Title order={4}>Plan Billing</Title>

      {
        payments.length === 0
          ? <Text ta="center" mt="md" size="md" c="gray">No payment processed yet.</Text>
          : <Table
            data={payments.filter(p => p.payed_at)}
            columns={[
              {
                title: "Date",
                key: "createdAt",
                render: (item) => item.createdAt ? getExtenseDatetime(item.createdAt) : ""
              },
              {
                title: "Description",
                key: "details",
                render: (item) => item.details?.text
              },
              {
                title: "Value",
                key: "value",
                render: (item) => `${item.data.currency.toUpperCase()} ${(item.data.amount / 100).toFixed(2)}`
              }
            ]}
          />
      }

    </Paper>
  </>
};
