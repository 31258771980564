import '@mantine/core/styles.css';
import '@mantine/dropzone/styles.css';
import "suneditor/dist/css/suneditor.min.css";
import { Notifications } from "@mantine/notifications";
import '@mantine/notifications/styles.css';
import 'dayjs/locale/en';
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import './App.css';
import { AppProvider } from "./contexts/app.context";
import { AuthProvider } from "./contexts/auth.context";
import { LanguageProvider } from "./contexts/language.context";
import { RequireAuth } from "./utility/requireAuth";
import routes from "./utility/routes";
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faCheckSquare, faCoffee } from '@fortawesome/free-solid-svg-icons'
import { NotFoundCompanyPage } from "./pages/not_found_company";

library.add(fab, faCheckSquare, faCoffee)

function App() {
  return <>
    <BrowserRouter>
      <LanguageProvider>
        <AuthProvider>
          <AppProvider>
            <Notifications />
            <Routes>
              {routes.filter(r => !r.private).map((r, i) => (
                <Route 
                  key={`${i}`} 
                  path={r.url} 
                  element={r.component}
                />
              ))}
              <Route element={<RequireAuth />}>
                {routes.filter(r => r.private).map((r, i) => <Route key={`${i}`} path={r.url} element={r.component} />)}
              </Route>
            </Routes>
          </AppProvider>
        </AuthProvider>
      </LanguageProvider>
    </BrowserRouter>
  </>
}

export default App;
